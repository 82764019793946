import type { FC } from "react";

import { Spin } from "antd";

import { Styled } from "./Spinner.styled";
import { SpinnerPositionEnum, SpinnerSizeEnum, SpinnerProps } from "./Spinner.types";

export const Spinner: FC<SpinnerProps> = ({
	fullWidth = false,
	size = SpinnerSizeEnum.Large,
	position = SpinnerPositionEnum.Relative,
	overlay,
}) => {
	return (
		<Styled.Root fullWidth={fullWidth} position={position}>
			<Spin size={size} />
			{overlay && <Styled.Overlay />}
		</Styled.Root>
	);
};
