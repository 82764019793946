import _ from "lodash";

import { DEFAULT_COLOR } from "../constants";

export const formatObjectValues = (obj) => {
	return Object.keys(obj).reduce((formattedObj, key) => {
		formattedObj[key] = typeof obj[key] === "number" ? obj[key].toFixed(2) : obj[key];

		return formattedObj;
	}, {});
};

export const getStringColor = (color: number) => {
	return color?.toString(16).toUpperCase().padStart(6, "0"); // Converts to hex
};

export const getUnicColors = (colors: string[], color: string) =>
	color && !colors.includes(color) ? [...colors, color] : colors;

export const parseErrors = (data) => {
	return Object.entries(data)
		.map(([param, msg]) => `${msg}`)
		.join(", ");
};

export const errorHandler = async (dxf: any, fileName: string) => {
	const errors = dxf?.entities?.reduce((acc: any, entity: any) => {
		// Check color
		// const hasNotCorrectColor =
		// 	"color" in entity ? getStringColor(entity?.color) !== DEFAULT_COLOR : null;

		const blockKeys = Object.keys(dxf.blocks);
		const filteredBlockKeys = _.omit(
			[...blockKeys],
			["*Model_Space", "*Paper_Space", "*Paper_Space"],
		);
		const hasBlocks = entity?.name && typeof entity.name === "string" && dxf.blocks[entity?.name];

		const hasDashedLines = entity.lineType === "K5LT32768";

		if (hasBlocks) {
			acc["blocksAmountError"] = `Файл ${fileName} не повинен містити кілька деталей.`;
		}

		if (hasDashedLines) {
			acc["dashedLinesError"] = `Файл ${fileName} не повинен містити ліній згинання.`;
		}

		return acc;
	}, {});

	return parseErrors(errors);
};

export async function generateRandomDigitNumber() {
	// Generate a random number between 0 and 999999
	const randomNumber = Math.floor(Math.random() * 10000000);

	// Ensure it's exactly 7 digits by padding with zeros if necessary
	return String(randomNumber).padStart(8, "0");
}

export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
