import React from "react";

function LogoIcon() {
	return (
		<svg
			id='Слой_1'
			data-name='Слой 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 5839.21 1748.38'
		>
			<title>candle_logo-01-01</title>
			<path
				className='cls-1'
				d='M7015.6-1528H1176.39V-3276.38H7015.6Zm-5791-48.24H6967.35V-3228.11H1224.64Z'
				transform='translate(-1176.39 3276.38)'
			></path>
			<path
				className='cls-1'
				d='M6614.28-3009.12c.12-70.51,57.59-126.83,127.11-126.68a127,127,0,0,1,126.69,127.08c-.17,70.63-57.65,127-127.16,126.72-70.59-.16-126.92-57.65-126.64-127.12m125.37-106.72c-58.79.72-107.11,49.7-105.41,110.21a107,107,0,0,0,108.29,105.69,107,107,0,0,0,105.69-108.3c0-1.33-.05-2.67-.12-4-1.73-57.51-49.55-104.42-108.45-103.59'
				transform='translate(-1176.39 3276.38)'
			></path>
			<path
				className='cls-1'
				d='M6803.46-2933.21h-20.33a1.71,1.71,0,0,1-1.94-1.33c-1.32-4.2-2.93-8.35-4-12.63-2.42-9.43-4.64-18.93-7-28.44-1.43-5.71-3.26-11.27-6.54-16.24a25.2,25.2,0,0,0-14.7-10.7,45.85,45.85,0,0,0-13.19-1.54H6718.3v70.74h-21v-161.2c2.65-.43,5.3-.87,8-1.27a219.93,219.93,0,0,1,28.2-2c10.42-.23,20.8.2,30.93,2.82,9.36,2.34,17.64,6.63,24,14.06a38.44,38.44,0,0,1,6.88,12.32,46.52,46.52,0,0,1,2.19,20.28,40.29,40.29,0,0,1-10.86,24.17,47,47,0,0,1-18.77,12.12,4.23,4.23,0,0,0-.91.66,5.65,5.65,0,0,0,.83.71c7.33,2.73,12.51,7.8,16.4,14.42s5.7,13.79,7.48,21.08c2.78,11.26,5.74,22.57,8.76,33.82a82.56,82.56,0,0,0,3,8.2M6718.3-3020c.47,0,.74.08,1.06.08,7.56,0,15.13.12,22.7-.08s14.66-1.82,21.08-5.86a27.63,27.63,0,0,0,12.12-15.24,33.77,33.77,0,0,0,1.26-14.3,28.25,28.25,0,0,0-3.21-10.6c-3.88-7.09-10.1-11.13-17.66-13.31-11.93-3.37-24-2.65-36.17-.63-1,.19-1.18.71-1.18,1.62Z'
				transform='translate(-1176.39 3276.38)'
			></path>
			<polygon
				className='cls-1'
				points='2237.93 1310.65 2237.93 448.53 2488.16 448.53 2774.12 894.32 2774.12 450.62 3032.79 450.62 3032.79 1314.85 2774.12 1314.85 2488.16 856.45 2488.16 1312.75 2237.93 1310.65'
			></polygon>
			<path
				className='cls-1'
				d='M4705.66-2826.41l-377.22,1.84v864.8h438s355,5.51,349.56-423.23-410.31-443.43-410.31-443.43m-7.36,656.85H4600.8v-441.57h84.67s147.2-12.84,158.22,200.55-145.34,241-145.34,241'
				transform='translate(-1176.39 3276.38)'
			></path>
			<polygon
				className='cls-1'
				points='4050.72 445.09 4321.19 445.09 4321.19 1113.67 4698.3 1113.67 4698.3 1315.54 4052.62 1315.54 4050.72 445.09'
			></polygon>
			<polygon
				className='cls-1'
				points='4820.89 440.26 4820.89 1313.59 5612.97 1313.59 5533.59 1112.32 5110.77 1112.32 5110.77 938.78 5461.58 938.78 5393.72 765.28 5110.62 765.28 5110.62 628.81 5339.57 628.81 5262.85 441.56 4820.89 440.26'
			></polygon>
			<path
				className='cls-1'
				d='M2956.43-2825.45h-319.9l-347.28,872.74h294l40.92-141.85h334.72l46.75,140.27h310.11Zm-258.38,529.1,100.89-297.76,108.3,300.22Z'
				transform='translate(-1176.39 3276.38)'
			></path>
			<path
				className='cls-1'
				d='M1993.69-2490.27h267.82s-24.33-355.64-419.62-342.72-412.52,378.1-412.52,378.1-68.72,399.63,292.18,485.52,528.55-150.37,545.7-326.56H1996.58s-15.69,136.7-139.43,133.81c-112-2.58-157.05-104.69-154.61-240.88,2.37-133.19,74.51-221.39,158.09-221.43,70.39,0,128.47,70.07,133.06,134.16'
				transform='translate(-1176.39 3276.38)'
			></path>
		</svg>
	);
}

export default React.memo(LogoIcon);
