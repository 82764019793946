import { message } from "antd";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload/interface";

import { ACCEPTED_FILE_FORMATS, MAX_FILE_SIZE } from "constants/common";

export const getFileUploaderProps = (
	callback: (fileList: UploadFile[]) => void,
	props: object = { fileList: [] },
	t: any,
) => ({
	name: "files",
	multiple: true,
	listType: "picture",
	defaultFileList: [],
	accept: ACCEPTED_FILE_FORMATS.join(", "),
	customRequest: () => false,
	onChange(info: UploadChangeParam) {
		callback(info?.fileList);
		// message.success(t("messageUploadedFile"));
	},
	beforeUpload(file: RcFile) {
		const { size, type } = file;
		const isOverMaxFileSize = !!size && size / 1024 / 1024 < MAX_FILE_SIZE;
		const isAcceptedFormats = type && ACCEPTED_FILE_FORMATS?.includes(type);

		if (!isOverMaxFileSize) {
			message.error(`${t("messageUploadFail")} ${MAX_FILE_SIZE}`);
			console.error("Error");
		}

		return isOverMaxFileSize;
	},
	...props,
});
