import React from "react";
import MaskedInput from "react-input-mask";

import { Input, InputProps } from "antd";

interface MaskedInputProps extends Omit<InputProps, "onChange"> {
	mask: string;
	maskChar?: string | null;
	formatChars?: { [key: string]: string };
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputMask: React.FC<MaskedInputProps> = ({
	mask,
	maskChar = null,
	formatChars,
	onChange,
	...inputProps
}) => {
	return (
		<MaskedInput
			{...inputProps}
			mask={mask}
			maskChar={maskChar}
			formatChars={formatChars}
			onChange={onChange}
		>
			{(inputProps: InputProps) => <Input {...inputProps} />}
		</MaskedInput>
	);
};
