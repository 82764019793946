import React, { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { materialsData, materialTypesData } from "constants/materials";

import { PlusOutlined } from "@ant-design/icons";
import { Form, Button, Select, InputNumber, Checkbox, Upload, Modal } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import clsx from "clsx";
// import DxfViewer from "components/DxfSingleViewer";
import { DeleteIcon } from "components/Icons";
import { useCheckoutFormContext } from "context/useCheckoutFormContext";
import { useItemsContext } from "context/useItemsContext";
import { useLoadingContext } from "context/useLoadingContext";
import { useUploadFilesContext } from "context/useUploadFilesContext";
import { GenericType, IItem, IOption } from "types";
import { isEmpty } from "underscore";
import { calculateTotalPrice, getDxfParams, sleep } from "utils";
import { convertContentToHtml } from "utils/convertToHtml";
import { getFileUploaderProps } from "utils/fileUploader";

import { DataType, IMaterialOption, IThicknessOption } from "../Calculator.types";

import styles from "./CalculatorFormTable.module.scss";

const { Option } = Select;

const FormItem = Form.Item;

export const CalculatorFormTable: React.FC = () => {
	const { t } = useTranslation();
	const anchorRef = useRef<HTMLDivElement>(null);
	const dxfRefs = useRef([]);

	const { items, setItems } = useItemsContext();
	const { uploadFiles, setUploadFiles } = useUploadFilesContext();
	const { showCheckoutForm, setShowCheckoutForm } = useCheckoutFormContext();
	const { loading, setLoading } = useLoadingContext();

	const [form] = Form.useForm();

	const [isSubmittable, setSubmittable] = useState(false);

	const [indeterminate, setIndeterminate] = useState(false);
	const [materialTypeAll, setMaterialTypeAll] = useState<IMaterialOption | null>(null);
	const [materialThicknessAll, setMaterialThicknessAll] = useState<IThicknessOption | null>(null);
	const [selectedList, setSelectedList] = useState([]);
	const [totalPrice, setTotalPrice] = useState<number>(0);

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState<GenericType | null>(null);

	const handleCancelModal = () => {
		setOpenModal(false);
		setModalContent(null);
	};

	const handleOpenModal = (event: MouseEvent<HTMLDivElement, MouseEvent>, index: GenericType) => {
		const parts = form.getFieldsValue()?.parts;

		const curItem = parts?.[index]?.svg;
		curItem && setModalContent(curItem);
		setOpenModal(true);
	};

	const onFinish = (values: DataType[]) => {
		setSubmittable(true);
		setItems(form.getFieldsValue()?.parts);
	};

	const onValuesChange = (changedFields: any, allFields: any): void => {
		const { parts } = allFields;
		const newParts = parts?.map((part: any) => {
			const materialData = materialsData?.find((i) => i.id === part?.materialThickness);
			const quantityValue = part?.quantity;
			const cutPriceValue = (part?.cutLength * (materialData?.cutPrice ?? 0)) / 1000;
			const punchesValue = part?.punches * (materialData?.punchesPrice ?? 0);
			const price = (cutPriceValue + punchesValue) * quantityValue;

			return { ...part, price: +price?.toFixed(2) };
		});

		if (!newParts?.some((i: IItem) => i.price === 0)) {
			setSubmittable(() => true);
		}

		const sum = newParts?.length && calculateTotalPrice(newParts);

		form.setFieldValue("parts", newParts);

		form?.setFieldValue("totalPrice", +sum?.toFixed(2));
		setTotalPrice(() => +sum?.toFixed(2));
	};

	const handleSelectAll = (e: CheckboxChangeEvent) => {
		const { checked } = e.target;
		const newParts = form
			?.getFieldsValue()
			?.parts?.map((item: { checked: boolean }) => ({ ...item, checked }));

		newParts && form?.setFieldValue("parts", newParts);
		newParts && form?.setFieldValue("checkAll", checked);

		setSelectedList(() => (checked ? newParts : []));
		setIndeterminate(() => false);
	};

	const handleSelectItem = useCallback((event: CheckboxChangeEvent, id: number) => {
		const { checked } = event.target;

		const newParts = form?.getFieldsValue()?.parts?.map((item: IItem) => ({
			...item,
			checked: item.id === id ? checked : item.checked,
		}));

		const checkedItems = newParts?.filter((f: { checked: boolean }) => f.checked);
		newParts?.length && form?.setFieldValue("parts", newParts);
		form?.setFieldValue("checkAll", checkedItems?.length === newParts?.length);

		setSelectedList(() => checkedItems);
		setIndeterminate(() => checkedItems?.length < newParts?.length && checkedItems?.length > 0);
	}, []);

	const handleQuantityAllItems = (value: number | null) => {
		form?.setFieldValue("quantity", value);
	};

	const handleMaterialTypeChangeAll = (id: number) => {
		const currentMaterial = materialTypesData?.find((type: { id: number }) => type.id === id);

		currentMaterial && setMaterialTypeAll(currentMaterial);
		currentMaterial?.id && form?.setFieldValue("materialType", currentMaterial?.id);
	};

	const handleMaterialThicknessChangeAll = (id: number) => {
		const currentMaterialThickness = materialTypeAll?.params?.find((el) => el.id === id);

		currentMaterialThickness && setMaterialThicknessAll(currentMaterialThickness);
		currentMaterialThickness &&
			form?.setFieldValue("materialThickness", currentMaterialThickness?.id);
	};

	const handleAcceptAll = () => {
		const newParts =
			materialTypeAll &&
			materialThicknessAll &&
			form.getFieldsValue()?.parts?.map((part: IItem) => {
				const isExist = selectedList.some((i: { id: number }) => i.id === part.id);

				if (isExist) {
					const quantity = form?.getFieldsValue()?.quantity;
					const materialThickness = materialThicknessAll?.id;
					const materialData = materialsData.find((i) => i.id === materialThickness);
					const cutPriceValue = (part?.cutLength * (materialData?.cutPrice ?? 0)) / 1000;
					const punchesValue = part?.punches * (materialData?.punchesPrice ?? 0);
					const price = (cutPriceValue + punchesValue) * quantity;

					return {
						...part,
						quantity,
						materialThickness,
						materialType: materialTypeAll.id,
						price: +price?.toFixed(2),
						checked: false,
					};
				}

				return part;
			});

		newParts?.length && form?.setFieldValue("parts", newParts);
		setMaterialTypeAll(() => null);
		setMaterialThicknessAll(() => null);
		setSelectedList(() => []);
		setIndeterminate(() => false);
		newParts && form?.setFieldValue("materialType", null);
		newParts && form?.setFieldValue("materialThickness", null);
		newParts && form?.setFieldValue("checkAll", false);

		if (!newParts?.some((i: IItem) => i.price === 0)) {
			setSubmittable(() => true);
		}

		const sum = newParts?.length ? calculateTotalPrice(newParts) : "0.00";

		form?.setFieldValue("totalPrice", +sum?.toFixed(2));
		setTotalPrice(() => +sum.toFixed(2));
	};

	const handleShowCheckoutForm = async () => {
		setShowCheckoutForm(true);
		await sleep(1000);
		anchorRef?.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
	};

	const handleBack = (value: number | null) => {
		setUploadFiles(null);
		setItems(null);
	};

	useEffect(() => {
		if (!form.getFieldsValue()?.parts?.length) {
			form.setFieldsValue({
				parts: items,
				totalPrice: 0,
				checkAll: false,
				materialType: null,
				materialThickness: null,
				quantity: 1,
			});
		}
	}, [items]);

	const uploadProps = getFileUploaderProps(
		async (files) => {
			setLoading(true);
			const parts = form?.getFieldsValue()?.parts;
			files?.length && setUploadFiles([...uploadFiles, ...files]);
			files?.length &&
				getDxfParams(files, (newParts) => {
					// Alternative way to update parts

					form?.setFieldValue("parts", parts ? [...parts, ...newParts] : [...newParts]);
					setLoading(() => false);
				});

			setSubmittable(() => false);
			setShowCheckoutForm(() => false);
			setLoading(() => false);
		},
		{ fileList: [] },
		t,
	);

	const getRef = useCallback(
		(element: any) => {
			const isExist = dxfRefs?.current?.find((i) => i?.id === element?.id);

			return element && !isExist && dxfRefs?.current?.push(element);
		},
		[dxfRefs?.current],
	);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	const selectedPartsTitle = selectedList?.length !== 1 ? t("parts") : t("part");

	return items?.length ? (
		<>
			<Form
				form={form}
				className={styles.formTable}
				onValuesChange={onValuesChange}
				onFinish={onFinish}
				autoComplete='off'
				size='small'
			>
				<table>
					<thead>
						<tr className={styles.formTableHeader}>
							<td className={styles.formTableCol} width={48}>
								<FormItem noStyle label='' name='checkAll'>
									<Checkbox
										indeterminate={indeterminate}
										onChange={handleSelectAll}
										checked={form.getFieldsValue()?.checkAll}
									/>
								</FormItem>
							</td>
							<td className={styles.formTableCol} width={10}></td>
							<td className={clsx(styles.formTableCol, styles.contentLeft)} width={380}>
								{selectedList?.length
									? `${t("selected")}: ${selectedList?.length} ${selectedPartsTitle}`
									: t("selectAll")}
							</td>

							<td
								className={clsx(
									styles.formTableCol,
									styles.formTableSelectBox,
									styles.alineItemsLeft,
								)}
								width={300}
							>
								{!!selectedList?.length && (
									<>
										<div className={styles.formTableSelect}>
											{materialTypeAll && <b>{t("of")}: </b>}
											<FormItem noStyle name='materialType'>
												<Select
													placeholder={t("chooseMaterial")}
													onChange={handleMaterialTypeChangeAll}
												>
													{materialTypesData.map((option: IOption) => (
														<Option key={option.id} value={option.id}>
															{option.name}
														</Option>
													))}
												</Select>
											</FormItem>
										</div>
										<div className={styles.formTableSelect}>
											{materialTypeAll && materialThicknessAll && <b>{t("thickness")}: </b>}
											{materialTypeAll && (
												<FormItem noStyle name='materialThickness'>
													<Select
														placeholder={t("thickness")}
														onChange={handleMaterialThicknessChangeAll}
													>
														{materialTypeAll?.params?.map((option: IThicknessOption) => (
															<Option key={`${materialTypeAll.id}-${option.id}`} value={option.id}>
																{option.thickness}
															</Option>
														))}
													</Select>
												</FormItem>
											)}
										</div>
									</>
								)}
							</td>

							<td className={styles.formTableCol}>
								{!!selectedList?.length && (
									<>
										<FormItem noStyle name='quantity'>
											<InputNumber min={1} max={1000} onChange={handleQuantityAllItems} />
										</FormItem>
										<span style={{ padding: "0 4px" }}> {t("psc")}</span>
									</>
								)}
							</td>
							<td className={styles.formTableCol}></td>
							<td className={clsx(styles.formTableCol, styles.contentRight)}>
								{!!selectedList?.length && (
									<Button
										type='primary'
										onClick={handleAcceptAll}
										htmlType='button'
										size='middle'
										disabled={isEmpty(materialTypeAll) && isEmpty(materialThicknessAll)}
									>
										{t("accept")}
									</Button>
								)}
							</td>
						</tr>
					</thead>
					<tbody>
						<Form.List name='parts'>
							{(fields, { remove, add }) => {
								return (
									<>
										{fields.map(({ key, name, ...field }, index: number) => {
											const parts = form.getFieldsValue()?.parts;
											const materialType = materialTypesData.find(
												(type: IMaterialOption) => type.id === parts?.[name]?.materialType,
											);

											const dxfData = name && parts?.[name]?.svg;
											const innerRef = dxfRefs?.current?.[index];

											return (
												parts?.length && (
													<tr key={key} className={styles.formTableRow}>
														<td className={styles.formTableCol} width={48}>
															<FormItem noStyle {...field} name={[name, "checked"]}>
																<Checkbox
																	onChange={(e) => handleSelectItem(e, parts[name]?.id)}
																	checked={parts[name]?.checked}
																	value={parts[name]?.checked}
																/>
															</FormItem>
														</td>
														<td
															className={clsx(styles.formTableCol)}
															height={120}
															width={110}
															style={{ textAlign: "center" }}
														>
															{
																<div
																	onClick={(e: any) => handleOpenModal(e, index)}
																	className={styles.previewBox}
																>
																	<div className={styles.dxfSVG}>
																		{convertContentToHtml(parts[name].svg)}
																	</div>
																</div>
															}
														</td>
														<td
															className={clsx(
																styles.formTableCol,
																styles.contentLeft,
																styles.width,
															)}
														>
															{parts?.[name]?.name}
														</td>
														<td
															className={clsx(
																styles.formTableCol,
																styles.formTableSelectBox,
																styles.alineItemsLeft,
															)}
															width={180}
														>
															<div className={styles.formTableSelect}>
																{materialType && <span>{t("of")}: </span>}
																<FormItem noStyle {...field} name={[name, "materialType"]}>
																	<Select placeholder={t("chooseMaterial")}>
																		{materialTypesData.map((option: IOption) => (
																			<Option key={option.id} value={option.id}>
																				{option.name}
																			</Option>
																		))}
																	</Select>
																</FormItem>
															</div>

															<div className={clsx(styles.formTableSelect)}>
																{materialType && parts[name]?.materialThickness && (
																	<span>{t("thickness")}: </span>
																)}
																{materialType && (
																	<FormItem noStyle {...field} name={[name, "materialThickness"]}>
																		<Select placeholder={t("thickness")}>
																			{materialType?.params?.map((option: IThicknessOption) => (
																				<Option key={option.id} value={option.id}>
																					{option.thickness}
																				</Option>
																			))}
																		</Select>
																	</FormItem>
																)}
															</div>
														</td>
														<td className={styles.formTableCol}>
															<FormItem noStyle {...field} name={[name, "quantity"]}>
																<InputNumber
																	min={1}
																	max={1000}
																	// onChange={(value) => handleQuantityChange(value, name)}
																/>
															</FormItem>
															<span style={{ padding: "0 4px" }}> {t("psc")}</span>
														</td>
														<td className={styles.formTableCol}>
															{parts[name]?.price ? parts[name]?.price.toFixed(2) : "0.00"} ₴
														</td>
														<td className={clsx(styles.formTableCol, styles.contentRight)}>
															<Button
																type='ghost'
																onClick={() => {
																	remove(name);
																}}
															>
																<DeleteIcon />
															</Button>
														</td>
													</tr>
												)
											);
										})}

										<tr className={styles.formTableSubFooter}>
											<td className={clsx(styles.formTableCol)}>
												<Upload
													{...uploadProps}
													showUploadList={false}
													className={styles.uploadNewFile}
												>
													<Button type='default' className={styles.addButton}>
														<PlusOutlined /> {t("addPart")}
													</Button>
												</Upload>
											</td>
										</tr>
									</>
								);
							}}
						</Form.List>
					</tbody>

					<tfoot className={styles.formTableFooter}>
						{/* <tr><td><div ref={} /></td></tr> */}
						<tr className={clsx(styles.formTableFootRow)}>
							<td className={clsx(styles.formTableCol)}>
								<FormItem noStyle name='totalPrice'>
									<h3 ref={anchorRef}>{`${t("totalPrice")}: ${totalPrice.toFixed(2)} ₴`}</h3>
								</FormItem>
							</td>

							<td className={clsx(styles.formTableCol)}>
								<Button
									type='primary'
									onClick={handleShowCheckoutForm}
									htmlType='submit'
									size='middle'
									disabled={!isSubmittable}
								>
									{t("goToOrder")}
								</Button>
							</td>
						</tr>
					</tfoot>
				</table>
			</Form>
			{!isEmpty(modalContent) && (
				<Modal
					open={openModal}
					footer={null}
					centered
					onCancel={handleCancelModal}
					width='700px'
					style={{ height: "95vh" }}
					className={styles.modal}
				>
					{/* {openModal && modalContent && <DxfViewer dxf={modalContent} id={"modal"} isFullWidth />} */}
					{openModal && modalContent && convertContentToHtml(modalContent)}
				</Modal>
			)}
		</>
	) : (
		<div className={styles.noData}>
			<Button type='primary' onClick={handleBack} htmlType='button' size='large'>
				{t("goBack")}
			</Button>
		</div>
	);
};
