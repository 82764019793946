import { DefaultTheme } from "styled-components/macro";

import COLORS from "./colors";
import { device, size } from "./device";
import SPACING from "./spacing";
import TYPOGRAPHY from "./typography";

export const theme: DefaultTheme = {
	colors: COLORS,
	device,
	size,
	spacing: SPACING,
	typography: TYPOGRAPHY,
};

export { COLORS, device, size, SPACING, TYPOGRAPHY };

export { GlobalStyles } from "./GlobalStyles";
