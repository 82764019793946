import ExcelJS from "exceljs";

export const getExcelFile = async (jsonData, userInfo) => {
	// Create Excel workbook and worksheet
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet("Список_замовлення");

	// Add headers
	worksheet.columns = [
		// { header: "Замовлення: ", key: "orderId", width: 15, alignment: { horizontal: "left" } },
		// { header: "Ім'я: ", key: "name", width: 40, alignment: { horizontal: "center" } },
		// { header: "Номер телефону: ", key: "phone", width: 40, alignment: { horizontal: "center" } },
		// { header: "Email:", key: "email", width: 40, alignment: { horizontal: "center" } },
		{ header: "Назва_файлу", key: "name", width: 40, alignment: { horizontal: "left" } },
		{ header: "Довжина_різу", key: "cutLength", width: 15, alignment: { horizontal: "center" } },
		{ header: "К-ть_пробивок", key: "punches", width: 15, alignment: { horizontal: "center" } },
		{ header: "Ширина_X", key: "widthX", width: 10, alignment: { horizontal: "center" } },
		{ header: "Висота_Y", key: "heightY", width: 10, alignment: { horizontal: "center" } },
		// { header: "Вага_заготовки", key: "weight", width: 15, alignment: { horizontal: "center" } },
		{
			header: "Тип_матеріалу",
			key: "materialType",
			width: 15,
			alignment: { horizontal: "center" },
		},
		{
			header: "Товщина_матеріалу",
			key: "materialThickness",
			width: 10,
			alignment: { horizontal: "center" },
		},
		{ header: "К-ть_деталей", key: "quantity", width: 10, alignment: { horizontal: "center" } },
		{ header: "Ціна_загальна", key: "price", width: 12, alignment: { horizontal: "center" } },
	];

	// Add additional information above the table
	if (userInfo) {
		// Create a new row for additional information
		// TODO Rework this
		const userInfoRow = worksheet.addRow([userInfo]);
		// Merge the cells in the row to span across all columns
		userInfoRow.getCell(1).alignment = { horizontal: "center" }; // Center the text
		userInfoRow.getCell(1).font = { bold: true, size: 14 }; // Apply styling
		// Leave empty rows below for spacing if needed
		worksheet.addRow([]);
	}

	// Add data
	jsonData?.forEach((row) => {
		worksheet.addRow(row);
	});

	// Customize the first row (headers)
	const headRow = worksheet.getRow(userInfo ? 6 : 1);

	// Set font-weight to bold and text alignment to center for all cells in the first row
	headRow.eachCell((cell) => {
		cell.font = { bold: true };
		cell.alignment = { horizontal: "center", vertical: "middle" };
	});

	// Add borders to the first row (top, bottom, left, right)
	headRow.eachCell((cell) => {
		cell.border = {
			top: { style: "thin" },
			left: { style: "thin" },
			bottom: { style: "thin" },
			right: { style: "thin" },
		};
	});

	// Create Excel file
	const excelBuffer = await workbook.xlsx.writeBuffer();

	return new Blob([excelBuffer], {
		type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	});
};
