import React, { createContext, Dispatch, useContext, useState } from "react";

import { IItem } from "types";

interface ILoadingProvider {
	children?: React.ReactNode;
}

interface IProvideLoading {
	loading: boolean;
	setLoading: Dispatch<boolean>;
}

export const LoadingContext = createContext<IProvideLoading>({
	loading: false,
	setLoading: () => [],
});

const useProvideLoading = (): IProvideLoading => {
	const [loading, setLoading] = useState<boolean>(false);

	return {
		loading,
		setLoading,
	};
};

export const ProvideLoading = ({ children }: ILoadingProvider): JSX.Element => {
	const loader = useProvideLoading();

	return <LoadingContext.Provider value={loader}>{children}</LoadingContext.Provider>;
};

export const useLoadingContext = (): any => {
	return useContext(LoadingContext);
};
