import type { FC } from "react";

import Container from "components/Container";
import { ReactParent } from "types";

import { useLoadingContext } from "../../context/useLoadingContext";
import LogoIcon from "../Icons/LogoIcon";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwithcer";
import { Spinner } from "../Spinner";
import { SpinnerPositionEnum } from "../Spinner/Spinner.types";

import styles from "./Layout.module.scss";

type LayoutProps = ReactParent;

const Layout: FC<LayoutProps> = ({ children }) => {
	const { loading } = useLoadingContext();

	return (
		<>
			<section className={styles.layout}>
				<header>
					<div className={styles.headerInner}>
						<a href='https://candle.ua/en/' target='_blank' rel='noreferrer'>
							<LogoIcon />
						</a>
						<LanguageSwitcher />
					</div>
				</header>
				<main className={styles.main}>
					<Container>{children}</Container>
				</main>
				{loading && <Spinner position={SpinnerPositionEnum.Fixed} overlay />}
			</section>
		</>
	);
};

export default Layout;
