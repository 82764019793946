import { useTranslation } from "react-i18next";

import { Button } from "antd";
import { Routes } from "types";

import checkImg from "./images/check_mark.png";
import styles from "./ThanksPage.module.scss";

export function ThanksPage() {
	const { t } = useTranslation();

	return (
		<div className={styles.root}>
			<>
				<div className={styles.imgWrapper}>
					<img className={styles.img} src={checkImg} alt='check_mark' />
				</div>

				<div className={styles.titleWrapper}>
					<h1>{t("thankYou")}</h1>
					<p>{t("receivedOrder")}</p>
					<p>
						{t("anyQuestions")}

						<a href='tel:+380737787848' className={styles.link}>
							+38(073) 778 78 48
						</a>
					</p>
				</div>

				<div className={styles.comebackLinkWrapper}>
					<Button
						htmlType='button'
						type='default'
						className={styles.comebackLink}
						href={Routes.Home}
					>
						{t("comeback")}
					</Button>
				</div>
			</>
		</div>
	);
}
