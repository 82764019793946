import { ApiUrl } from "types";
import { post } from "utils/api";

// File Upload to the server
const sendCheckoutForm = async (formData: FormData) => {
	try {
		const response = await post(`${ApiUrl.CheckoutFormSend}`, formData);

		if (response?.status === 200) {
			console.info(response?.message);
		} else if (response?.status === 500) {
			console.error(response?.message);
		} else {
			// Handle other status codes here
			console.error("Unexpected status code: " + response?.status);
		}

		return response;
	} catch (e) {
		console.error(e);
	}
};

const checkoutFormService = {
	sendCheckoutForm,
};

export default checkoutFormService;
