import { useTranslation } from "react-i18next";

import { Button, Upload } from "antd";

import { useUploadFilesContext } from "context/useUploadFilesContext";
import { getFileUploaderProps } from "utils/fileUploader";

import styles from "./UplodaFiles.module.scss";

const { Dragger } = Upload;

export function UploadFiles() {
	const { setUploadFiles } = useUploadFilesContext();
	const { t } = useTranslation();

	const draggerProps = getFileUploaderProps(
		(files) => {
			files?.length && setUploadFiles(files);
		},
		{ fileList: [] },
	);

	return (
		<div className={styles.root}>
			<Dragger {...draggerProps} className={styles.dragger}>
				<div className={styles.draggerContent}>
					<p>
						<Button type='primary' size='large'>
							{t("uploadPart")}
						</Button>
					</p>
					<p className='ant-upload-text'>{t("dragFiles")}</p>
					<p className='ant-upload-hint'>
						{t("support")} <b>.DXF</b> {t("upTo")} 100MB
					</p>
				</div>
			</Dragger>
		</div>
	);
}
