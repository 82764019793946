import { useEffect, useState } from "react";

import { useItemsContext } from "context/useItemsContext";
import { useUploadFilesContext } from "context/useUploadFilesContext";
import { getDxfParams } from "utils";

import { CalculatorFormTable } from "./CalculatorFormTable";

export function Calculator() {
	const { uploadFiles } = useUploadFilesContext();
	const { items, setItems } = useItemsContext();

	useEffect(() => {
		if (!items?.length && uploadFiles?.length) {
			uploadFiles && getDxfParams(uploadFiles, (parts) => setItems(parts));
		}
	}, []);

	return <CalculatorFormTable />;
}
