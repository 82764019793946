import styled from "styled-components/macro";

import { SpinnerPositionEnum } from "./Spinner.types";

interface IStyledProps {
	fullWidth: boolean;
	position?: SpinnerPositionEnum;
}

interface IStyledOverlayProps {
	fixed?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Styled {
	export const Root = styled.section<IStyledProps>`
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		min-height: 100%;
		${({ fullWidth, position, theme: { size } }) => `
			width: 100%;
    	max-width: ${fullWidth || position !== SpinnerPositionEnum.Relative ? "100%" : size.large};
    	position: ${position};
    `}

		.ant-spin {
			transform: ${({ position }) => (position === "fixed" ? "translateY(-50%)" : "none")};
		}
	`;

	export const Overlay = styled.div<IStyledOverlayProps>`
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(92, 92, 96, 0.32);
	`;
}
