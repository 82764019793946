import React, { createContext, Dispatch, useContext, useState } from "react";

import { IItem } from "types";

interface IItemsProvider {
	children?: React.ReactNode;
}

interface IProvideItems {
	items: IItem[];
	initItems: IItem[];
	setItems: Dispatch<IItem[]>;
	setInitItems: Dispatch<IItem[]>;
}

export const ItemsContext = createContext<IProvideItems>({
	items: [],
	initItems: [],
	setItems: () => [],
	setInitItems: () => [],
});

const useProvideItems = (): IProvideItems => {
	const [items, setItems] = useState<IItem[]>([]);
	const [initItems, setInitItems] = useState<IItem[]>([]);

	return {
		items,
		initItems,
		setItems,
		setInitItems,
	};
};

export const ProvideItems = ({ children }: IItemsProvider): JSX.Element => {
	const loader = useProvideItems();

	return <ItemsContext.Provider value={loader}>{children}</ItemsContext.Provider>;
};

export const useItemsContext = (): any => {
	return useContext(ItemsContext);
};
