export enum SpinnerSizeEnum {
	Large = "large",
	Default = "default",
	Small = "small",
}

export enum SpinnerPositionEnum {
	Fixed = "fixed",
	Relative = "relative",
	Absolute = "absolute",
}

export type SpinnerProps = {
	size?: SpinnerSizeEnum;
	fullWidth?: boolean;
	position?: SpinnerPositionEnum;
	overlay?: boolean;
};
