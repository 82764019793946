import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";

import styles from "./ContactInfo.module.scss";

export const ContactInfo: React.FC = () => {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	return (
		<>
			<div className={styles.box}>
				<h4 onClick={() => setOpen(true)} className={styles.link} style={{ color: "#4096ff" }}>
					{t("requirements")}
				</h4>
				<br />
				<h4>{t("contactTitle")}</h4>

				<a href='mailto:lviv.laser.cut@gmail.com' className={styles.link}>
					lviv.laser.cut@gmail.com
				</a>
				<a href='tel:+380737787848' className={styles.link}>
					+38(073) 778 78 48
				</a>
			</div>
			<Modal
				title={t("pleaseCheck")}
				centered
				open={open}
				onOk={() => setOpen(false)}
				onCancel={() => setOpen(false)}
				width={1000}
				cancelButtonProps={{ style: { display: "none" } }}
			>
				<p>{t("firstReq")}</p>
				<p>{t("secondReq")}</p>
			</Modal>
		</>
	);
};
