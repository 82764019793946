import React, { createContext, Dispatch, ReactNode, useContext, useState } from "react";

import { ICheckoutForm, Storage } from "types";

interface ICheckoutFormProvider {
	children?: ReactNode;
}

interface IProvideCheckoutForm {
	checkoutForm: Partial<ICheckoutForm> | null;
	showCheckoutForm: boolean | null;
	setCheckoutForm: Dispatch<ICheckoutForm | null>;
	setShowCheckoutForm: Dispatch<boolean | null>;
}

export const CheckoutFormContext = createContext<IProvideCheckoutForm>({
	checkoutForm: { comment: "" },
	showCheckoutForm: false,
	setCheckoutForm: () => ({}),
	setShowCheckoutForm: () => false,
});

const useProviderCheckoutForm = (): IProvideCheckoutForm => {
	const CheckoutFormData = localStorage.getItem(Storage.CheckoutForm) || {};

	const [showCheckoutForm, setShowCheckoutForm] = useState<boolean | null>(false);
	const [checkoutForm, setCheckoutForm] = useState<Partial<ICheckoutForm> | null>(CheckoutFormData);

	return {
		checkoutForm,
		showCheckoutForm,
		setCheckoutForm,
		setShowCheckoutForm,
	};
};

export const ProviderCheckoutForm = ({ children }: ICheckoutFormProvider): JSX.Element => {
	const loader = useProviderCheckoutForm();

	return <CheckoutFormContext.Provider value={loader}>{children}</CheckoutFormContext.Provider>;
};

export const useCheckoutFormContext = (): any => {
	return useContext(CheckoutFormContext);
};
