import { useCheckoutFormContext } from "context/useCheckoutFormContext";
import { useUploadFilesContext } from "context/useUploadFilesContext";
import { Calculator } from "features/calculator";
import { CheckoutForm } from "features/checkoutForm";
import { ContactInfo } from "features/contactInfo";
import { UploadFiles } from "features/uploadFiles";

import styles from "./HomePage.module.scss";

export function HomePage() {
	const { uploadFiles } = useUploadFilesContext();
	const { showCheckoutForm } = useCheckoutFormContext();

	return (
		<>
			<div className={styles.root}>{uploadFiles?.length ? <Calculator /> : <UploadFiles />}</div>
			{showCheckoutForm && <CheckoutForm />}
			<ContactInfo />
		</>
	);
}
