import { message } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import { Helper } from "dxf";
import DxfParser from "dxf-parser";
import {
	calculateSizeParams,
	calculateGeneralParams,
	getNumberOfClosedEntities,
	errorHandler,
} from "utils";
import { v4 as uuid } from "uuid";

const showMessageError = (data: string) => message.error(data);

export const getDxfParams = (
	files: UploadFile[],
	callback: (parts: Awaited<unknown>[]) => void,
) => {
	const promises = files?.map((file) => {
		return new Promise((resolve) => {
			const fileReader = new FileReader();

			fileReader.onload = async (event) => {
				const contents = event.target?.result;
				try {
					const parser = new DxfParser();

					if (typeof contents === "string") {
						// Another approach
						const dxf = parser.parseSync(contents);

						const helper = new Helper(contents);

						// Alternative parser
						// const dxf3 = helper.parse();

						const errors = await errorHandler(dxf, file?.name);

						if (errors?.length) {
							showMessageError(errors);

							return resolve(null);
						}

						// End params calculation
						const punches = getNumberOfClosedEntities(dxf?.entities)?.length + 1;
						const params = calculateGeneralParams(dxf);

						const sizeParams = calculateSizeParams(dxf?.entities);

						const svg = helper.toSVG();
						const id = uuid();
						const part = {
							id: id,
							name: file?.name,
							uid: file?.uid,
							punches,
							cutLength: +params?.cutLength ? +params?.cutLength?.toFixed(2) : "-",
							weight: params?.weight / 1000,
							widthX: +sizeParams?.lengthX ? +sizeParams?.lengthX?.toFixed(2) : "-",
							heightY: +sizeParams?.lengthY ? +sizeParams?.lengthY?.toFixed(2) : "-",
							materialType: null,
							materialThickness: null,
							quantity: 1,
							price: 0,
							checked: false,
							dxfData: dxf,
							svg,
						};

						resolve(part);
					} else {
						resolve(null);
					}
				} catch (err: any) {
					return console.error(err.stack);
				}
			};

			file.originFileObj && fileReader.readAsText(file.originFileObj);
		});
	});

	Promise.all(promises).then((parts) => {
		const filteredData = parts?.filter((part) => part !== null);

		filteredData?.length && callback(filteredData);
	});
};
