import React, { createContext, Dispatch, useContext, useState } from "react";

import { UploadFile } from "antd/es/upload/interface";

interface IUploadFilesProvider {
	children?: React.ReactNode;
}

interface IProvideUploadFiles {
	uploadFiles: UploadFile[];
	initUploadFiles: UploadFile[];
	setUploadFiles: Dispatch<UploadFile[]>;
	setInitUploadFiles: Dispatch<UploadFile[]>;
}

export const UploadFilesContext = createContext<IProvideUploadFiles>({
	uploadFiles: [],
	initUploadFiles: [],
	setUploadFiles: () => [],
	setInitUploadFiles: () => [],
});

const useProvideUploadFiles = (): IProvideUploadFiles => {
	const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
	const [initUploadFiles, setInitUploadFiles] = useState<UploadFile[]>([]);

	return {
		uploadFiles,
		initUploadFiles,
		setUploadFiles,
		setInitUploadFiles,
	};
};

export const ProvideUploadFiles = ({ children }: IUploadFilesProvider): JSX.Element => {
	const loader = useProvideUploadFiles();

	return <UploadFilesContext.Provider value={loader}>{children}</UploadFilesContext.Provider>;
};

export const useUploadFilesContext = () => {
	return useContext(UploadFilesContext);
};
