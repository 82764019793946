export const materialTypesData = [
	{
		id: 1,
		name: "Ст3",
		params: [
			{
				id: 1,
				thickness: 0.5,
				cutPrice: 16.5,
				punchesPrice: 0.41,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 2,
				thickness: 0.8,
				cutPrice: 16.5,
				punchesPrice: 0.41,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 3,
				thickness: 1,
				cutPrice: 16.5,
				punchesPrice: 0.41,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 4,
				thickness: 1.5,
				cutPrice: 19.5,
				punchesPrice: 0.62,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 5,
				thickness: 2,
				cutPrice: 22.16,
				punchesPrice: 1.1,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 6,
				thickness: 2.5,
				cutPrice: 23.14,
				punchesPrice: 1.1,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 7,
				thickness: 3,
				cutPrice: 28.04,
				punchesPrice: 1.43,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 8,
				thickness: 4,
				cutPrice: 31.25,
				punchesPrice: 1.43,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 9,
				thickness: 5,
				cutPrice: 34.13,
				punchesPrice: 1.43,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 10,
				thickness: 6,
				cutPrice: 36.58,
				punchesPrice: 1.74,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 11,
				thickness: 8,
				cutPrice: 47.6,
				punchesPrice: 2.69,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 12,
				thickness: 10,
				cutPrice: 58.52,
				punchesPrice: 2.36,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 13,
				thickness: 12,
				cutPrice: 75.59,
				punchesPrice: 3.3,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 14,
				thickness: 14,
				cutPrice: 86.45,
				punchesPrice: 4.37,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 15,
				thickness: 15,
				cutPrice: 96.91,
				punchesPrice: 4.87,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 16,
				thickness: 16,
				cutPrice: 109.72,
				punchesPrice: 5.57,
				materialName: "Ст3",
				materialId: 1,
			},
		],
	},
	{
		id: 2,
		name: "AISI",
		params: [
			{
				id: 17,
				thickness: 0.8,
				cutPrice: 26.66,
				punchesPrice: 0.66,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 18,
				thickness: 1,
				cutPrice: 26.66,
				punchesPrice: 0.66,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 19,
				thickness: 1.5,
				cutPrice: 27.43,
				punchesPrice: 0.66,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 20,
				thickness: 2,
				cutPrice: 35.46,
				punchesPrice: 1.75,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 21,
				thickness: 2.5,
				cutPrice: 66.39,
				punchesPrice: 3.05,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 22,
				thickness: 3,
				cutPrice: 71.25,
				punchesPrice: 3.05,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 23,
				thickness: 4,
				cutPrice: 74.44,
				punchesPrice: 3.4,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 24,
				thickness: 5,
				cutPrice: 80.21,
				punchesPrice: 3.4,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 25,
				thickness: 6,
				cutPrice: 84.76,
				punchesPrice: 4.03,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 26,
				thickness: 8,
				cutPrice: 103.91,
				punchesPrice: 5.91,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 27,
				thickness: 10,
				cutPrice: 123.06,
				punchesPrice: 7.3,
				materialName: "AISI",
				materialId: 2,
			},
		],
	},
	{
		id: 3,
		name: "Оц",
		params: [
			{
				id: 28,
				thickness: 0.8,
				cutPrice: 26.66,
				punchesPrice: 0.66,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 29,
				thickness: 1,
				cutPrice: 26.66,
				punchesPrice: 0.66,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 30,
				thickness: 1.5,
				cutPrice: 27.43,
				punchesPrice: 0.66,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 31,
				thickness: 2,
				cutPrice: 35.46,
				punchesPrice: 1.75,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 32,
				thickness: 2.5,
				cutPrice: 66.39,
				punchesPrice: 3.05,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 33,
				thickness: 3,
				cutPrice: 71.25,
				punchesPrice: 3.05,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 34,
				thickness: 4,
				cutPrice: 74.44,
				punchesPrice: 3.4,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 35,
				thickness: 5,
				cutPrice: 80.21,
				punchesPrice: 3.4,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 36,
				thickness: 6,
				cutPrice: 84.76,
				punchesPrice: 4.03,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 37,
				thickness: 8,
				cutPrice: 103.91,
				punchesPrice: 5.91,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 38,
				thickness: 10,
				cutPrice: 123.06,
				punchesPrice: 7.3,
				materialName: "Оц",
				materialId: 3,
			},
		],
	},
];

export const materialsData = [
	...materialTypesData[0].params,
	...materialTypesData[1].params,
	...materialTypesData[2].params,
];
