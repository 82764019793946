export enum DxfEntityTypesEnum {
	Arc = "ARC",
	Circle = "CIRCLE",
	Ellipse = "ELIPSE",
	Line = "LINE",
	Spline = "SPLINE",
	Rectangle = "RECTANGLE",
	Polygon = "POLYGON",
	LwPolyline = "LWPOLYLINE",
	Polyline = "POLYLINE",
	Insert = "INSERT",
	MText = "MTEXT",
}
