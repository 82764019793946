import { GenericType, IItem } from "../types";
import { DxfEntityTypesEnum } from "../types/files";
const getDistance = (point1: { x: number; y: number }, point2: { x: number; y: number }) => {
	const { x: x1, y: y1 } = point1;
	const { x: x2, y: y2 } = point2;
	const dx = x2 - x1;
	const dy = y2 - y1;

	return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
};

const getCircleDistance = (radius: number) => 2 * Math.PI * Math.abs(radius);
const getArcDistance = (radius: number, endAngle: number, startAngle: number) =>
	Math.abs(radius) * Math.abs(endAngle - startAngle);

function calculateCentralAngle(startAngle, endAngle) {
	return Math.abs(endAngle - startAngle);
}

// Helper function to check if two points are equal
function arePointsEqual(
	point1: { x: number; y: number; z: number },
	point2: { x: number; y: number; z: number },
) {
	return point1.x === point2.x && point1.y === point2.y && point1.z === point2.z;
}

// Helper function to check if the knot vector is closed
function isKnotVectorClosed(knots: number[]) {
	// Replace this with your logic to check if the knot vector is closed
	// For example, you can check if the first and last knot values are equal
	return knots[0] === knots[knots.length - 1];
}

export const calculateGeneralParams = (data: any) => {
	const { entities } = data;

	return entities?.reduce(
		(
			acc: {
				cutLength: number;
				totalArea: number;
				widthX: number;
				heightY: number;
				weight: number;
			},
			entity: any,
		) => {
			if (entity.type === DxfEntityTypesEnum.Line) {
				let start;
				let end;

				if (entity.vertices) {
					start = entity.vertices[0];
					end = entity.vertices[1];
				} else {
					start = entity.start;
					end = entity.end;
				}

				// Get Cut Length
				const cutLength = getDistance(start, end);

				// Get total area
				const lineArea = 0;

				acc = {
					...acc,
					cutLength: acc.cutLength + cutLength,
					totalArea: (acc.totalArea += lineArea),
					weight: acc.weight + entity.lineweight,
				};
			} else if (entity.type === DxfEntityTypesEnum.Arc) {
				let radius;
				let angleLength;
				const { startAngle, endAngle, lineweight } = entity;

				if (entity?.radius) {
					radius = entity?.radius;
				} else {
					radius = entity?.r;
				}

				const angleLengthCalcualte = calculateCentralAngle(startAngle, endAngle);

				if (entity?.angleLength) {
					angleLength = entity?.angleLength;
				} else {
					angleLength = angleLengthCalcualte;
				}

				// Calculate the arc length
				const cutLength = Math.abs(radius) * Math.abs(angleLength);

				// Get total area
				const arcArea = radius * radius * angleLength;

				acc = {
					...acc,
					cutLength: acc.cutLength + cutLength,
					totalArea: (acc.totalArea += arcArea),
					weight: acc.weight + lineweight,
				};
			} else if (entity.type === DxfEntityTypesEnum.Circle) {
				let radius;
				const { lineweight } = entity;

				if (entity?.radius) {
					radius = entity?.radius;
				} else {
					radius = entity?.r;
				}

				// Get Cut Length
				const cutLength = getCircleDistance(radius);

				// Get total area
				const circleArea = Math.PI * Math.abs(radius) * Math.abs(radius);

				acc = {
					...acc,
					cutLength: acc.cutLength + cutLength,
					totalArea: (acc.totalArea += circleArea),
					weight: acc.weight + lineweight,
				};
			} else if (entity.type === DxfEntityTypesEnum.Ellipse) {
				const { majorAxis, minorAxis, lineweight } = entity;

				// Get Cut Length
				const cutLength = Math.PI * majorAxis * minorAxis;

				// Get total area
				const area = Math.PI * majorAxis * minorAxis;

				acc = {
					...acc,
					cutLength: acc.cutLength + cutLength,
					totalArea: (acc.totalArea += area),
					weight: acc.weight + lineweight,
				};
			} else if (entity.type === DxfEntityTypesEnum.Rectangle) {
				const { width, height, lineweight } = entity;

				// Get Cut Length
				const cutLength = 2 * (width + height);

				const rectangleArea = width * height;

				acc = {
					...acc,
					cutLength: acc.cutLength + cutLength,
					totalArea: (acc.totalArea += rectangleArea),
					weight: acc.weight + lineweight,
				};
			} else if (entity.type === DxfEntityTypesEnum.Spline) {
				const { controlPoints } = entity;

				const pointsData = controlPoints?.reduce(
					(
						totalCount: { totalLength: number; totalArea: number },
						currentPoint: { x: number; y: number },
						index: number,
					) => {
						if (index === 0) return totalCount;

						const previousPoint = controlPoints[index - 1];
						const nextPoint = controlPoints[index + 1];

						// Get Cut Length
						const distance = getDistance(previousPoint, currentPoint);

						// Get total area
						const base = nextPoint?.x - currentPoint?.x;
						const height = (currentPoint?.y + nextPoint?.y) / 2;

						totalCount = {
							totalLength: totalCount?.totalLength + distance,
							totalArea: totalCount?.totalArea + base * height,
						};

						return totalCount;
					},
					{ totalLength: 0, totalArea: 0 },
				);

				acc = {
					...acc,
					cutLength: acc.cutLength + pointsData?.totalLength,
					totalArea: acc.totalArea + Math.abs(pointsData?.totalArea) / 2.0,
					weight: acc.weight + entity.lineweight,
				};
			} else if (entity.type === DxfEntityTypesEnum.LwPolyline) {
				let cutLength = 0;
				let area = 0;

				const { vertices } = entity;

				// Iterate through vertices of the LWPOLYLINE
				for (let i = 1; i < vertices.length - 1; i++) {
					const x1 = vertices[0].x;
					const y1 = vertices[0].y;
					const x2 = vertices[i].x;
					const y2 = vertices[i].y;
					const x3 = vertices[i + 1]?.x;
					const y3 = vertices[i + 1]?.y;

					const startPoint = vertices[i];
					const endPoint = vertices[i + 1];

					// Calculate the distance between two consecutive vertices
					const dx = endPoint?.x - startPoint?.x;
					const dy = endPoint?.y - startPoint?.y;
					const segmentLength = Math.sqrt(dx * dx + dy * dy);

					if (startPoint.bulge && startPoint.bulge !== 0) {
						const radius = (segmentLength / 2) * (1 / Math.abs(startPoint.bulge));
						const arcLength = 2 * Math.PI * radius * Math.abs(startPoint.bulge);
						cutLength += arcLength;
					} else {
						cutLength += segmentLength; // Straight line segment
					}

					const currentVertex = vertices[i];

					const nextVertex = vertices[(i + 1) % vertices.length]; // Wrap around to the first vertex

					// Take the absolute value of the calculated area
					area += currentVertex.x * nextVertex.y - nextVertex.x * currentVertex.y;

					// Calculate the area of the triangle using the shoelace formula.
					// area2 += 0.5 * Math.abs(x1 * (y2 - y3) + x2 * (y3 - y1) + x3 * (y1 - y2));
				}

				// Add the polyline length to the total length
				acc = {
					...acc,
					cutLength: acc.cutLength + cutLength,
					totalArea: acc.totalArea + Math.abs(area) / 2.0,
					weight: acc.weight + entity.lineweight,
				};
			} else if (entity.type === DxfEntityTypesEnum.Polygon) {
				const { vertices } = entity;

				// Get Cut Length
				const cutLength = vertices?.reduce(
					(totalLength: number, currentPoint: { x: number; y: number }, index: number) => {
						if (index === 0) return totalLength;

						const previousPoint = vertices[index - 1];
						const distance = getDistance(previousPoint, currentPoint);

						return totalLength + distance;
					},
					0,
				);

				acc = {
					...acc,
					cutLength: acc.cutLength + cutLength,
					weight: acc.weight + entity.lineweight,
				};
			} else if (entity.type === DxfEntityTypesEnum.Insert) {
				let totalLength = 0;
				const blockDefinition = data?.blocks[entity?.name];

				if (blockDefinition) {
					totalLength = calculateGeneralParams(blockDefinition.entities);
				}

				acc = {
					...acc,
					cutLength: acc.cutLength + totalLength,
					weight: acc.weight + entity.lineweight,
				};
			}

			return acc;
		},
		{ cutLength: 0, totalArea: 0, widthX: 0, heightY: 0, weight: 0 },
	);
};

export function calculateSizeParams(entities: any) {
	let minX = Infinity;
	let maxX = -Infinity;
	let minY = Infinity;
	let maxY = -Infinity;

	for (const entity of entities) {
		if (entity.type === DxfEntityTypesEnum.Line) {
			// Check extreme points for the line
			if (entity?.vertices) {
				// Check start and end points of the line
				minX = Math.min(minX, entity.vertices?.[0]?.x, entity?.vertices?.[1]?.x);
				minY = Math.min(minY, entity.vertices?.[0]?.y, entity?.vertices?.[1]?.y);
				maxX = Math.max(maxX, entity.vertices?.[0]?.x, entity?.vertices?.[1]?.x);
				maxY = Math.max(maxY, entity.vertices?.[0]?.y, entity?.vertices?.[1]?.y);
			} else {
				minX = Math.min(minX, entity.start.x, entity.end.x);
				maxX = Math.max(maxX, entity.start.x, entity.end.x);
				minY = Math.min(minY, entity.start.y, entity.end.y);
				maxY = Math.max(maxY, entity.start.y, entity.end.y);
			}
		} else if (entity.type === DxfEntityTypesEnum.Arc) {
			const radius = entity.radius;

			// Check extreme points for the arc
			if (entity?.center) {
				minX = Math.min(minX, entity.center.x - radius);
				maxX = Math.max(maxX, entity.center.x + radius);
				minY = Math.min(minY, entity.center.y - radius);
				maxY = Math.max(maxY, entity.center.y + radius);
			} else {
				minX = Math.min(
					minX,
					entity.centerX + entity.radius * Math.cos(entity.startAngle),
					entity.centerX + entity.radius * Math.cos(entity.startAngle + entity.angleLength),
				);
				minY = Math.min(
					minY,
					entity.centerY + entity.radius * Math.sin(entity.startAngle),
					entity.centerY + entity.radius * Math.sin(entity.startAngle + entity.angleLength),
				);
				maxX = Math.max(
					maxX,
					entity.centerX + entity.radius * Math.cos(entity.startAngle),
					entity.centerX + entity.radius * Math.cos(entity.startAngle + entity.angleLength),
				);
				maxY = Math.max(
					maxY,
					entity.centerY + entity.radius * Math.sin(entity.startAngle),
					entity.centerY + entity.radius * Math.sin(entity.startAngle + entity.angleLength),
				);
			}
		} else if (entity.type === DxfEntityTypesEnum.Circle) {
			const radius = entity.radius;

			// Check extreme points for the circle
			if (entity?.center) {
				minX = Math.min(minX, entity.center.x - radius);
				maxX = Math.max(maxX, entity.center.x + radius);
				minY = Math.min(minY, entity.center.y - radius);
				maxY = Math.max(maxY, entity.center.y + radius);
			} else {
				minX = Math.min(minX, entity.centerX - entity.radius);
				minY = Math.min(minY, entity.centerY - entity.radius);
				maxX = Math.max(maxX, entity.centerX + entity.radius);
				maxY = Math.max(maxY, entity.centerY + entity.radius);
			}
		} else if (entity.type === DxfEntityTypesEnum.Ellipse) {
			const widthX = entity.majorAxis;
			const heightY = entity.minorAxis;

			if (widthX > maxX) {
				maxX = widthX;
			}
			if (widthX < minX) {
				minX = widthX;
			}

			// Update max and min values for height (Y)
			if (heightY > maxY) {
				maxY = heightY;
			}
			if (heightY < minY) {
				minY = heightY;
			}
		} else if (entity.type === DxfEntityTypesEnum.Rectangle) {
			const { width, height } = entity;

			// Check extreme points for the rectangle
			minX = Math.min(minX, entity.x, entity.x + width);
			maxX = Math.max(maxX, entity.x, entity.x + width);
			minY = Math.min(minY, entity.y, entity.y + height);
			maxY = Math.max(maxY, entity.y, entity.y + height);
		} else if (entity.type === DxfEntityTypesEnum.Spline) {
			const { controlPoints } = entity;

			// Calculate the area using the trapezoidal rule
			for (let i = 0; i < controlPoints?.length - 1; i++) {
				const x1 = controlPoints?.[i].x;
				const y1 = controlPoints[i].y;
				const x2 = controlPoints[i + 1].x;
				const y2 = controlPoints[i + 1].y;

				// Check extreme points for the spline segment
				minX = Math.min(minX, x1, x2);
				maxX = Math.max(maxX, x1, x2);
				minY = Math.min(minY, y1, y2);
				maxY = Math.max(maxY, y1, y2);
			}
		} else if (entity.type === DxfEntityTypesEnum.LwPolyline) {
			const vertices = entity.vertices;

			// Check extreme points
			minX = vertices[0].x;
			minY = vertices[0].y;
			maxX = vertices[0].x;
			maxY = vertices[0].y;

			for (const vertex of vertices) {
				minX = Math.min(minX, vertex.x);
				minY = Math.min(minY, vertex.y);
				maxX = Math.max(maxX, vertex.x);
				maxY = Math.max(maxY, vertex.y);
			}
		}
	}

	const lengthX = Math.abs(maxX - minX);
	const lengthY = Math.abs(maxY - minY);
	const extremePoints = [minX, minY, maxX, maxY];
	const squareArea = lengthX * lengthY;

	return {
		squareArea,
		lengthX,
		lengthY,
		extremePoints,
	};
}

export function getNumberOfClosedEntities(entities: GenericType[] | any | []) {
	return entities?.reduce((amount: any, entity: any) => {
		if (entity.type === DxfEntityTypesEnum.Line) {
			const [point1, point2] = entity.vertices;
			const { x: x1, y: y1, z: z1 } = point1;
			const { x: x2, y: y2, z: z2 } = point2;

			if (x1 === x2 && y1 === y2 && z1 === z2) {
				amount += entity;
			}
		} else if (entity.type === DxfEntityTypesEnum.Circle) {
			amount.push(entity);
		} else if (entity.type === DxfEntityTypesEnum.Ellipse) {
			// Check if the ratio of majorAxis to minorAxis is close to 1
			const ratio = entity.majorAxis / entity.minorAxis;
			// Adjust this threshold as needed
			const threshold = 0.95;

			const isClosed = Math.abs(ratio - 1) < threshold;

			if (isClosed) amount += entity;
		} else if (entity.type === DxfEntityTypesEnum.Arc) {
			const connectedArc = amount.find(
				(previousArc: any) => Math.abs(previousArc.endAngle - entity.startAngle) < 1e-6,
			);

			if (connectedArc) {
				connectedArc.endAngle = entity.endAngle;
			} else {
				amount.push(entity);
			}

			// if (
			//  Math.abs(entity.startAngle - entity.endAngle) < 1e-12 &&
			//  Math.abs(entity.angleLength - 2 * Math.PI) < 1e-12
			// ) {
			//  amount.push(entity);
			// }
		} else if (entity.type === DxfEntityTypesEnum.Spline) {
			if (
				arePointsEqual(
					entity.controlPoints[0],
					entity.controlPoints[entity.controlPoints.length - 1],
				)
			) {
				amount.push(entity);
			} else if (entity.type === DxfEntityTypesEnum.LwPolyline) {
				const firstVertex = entity.vertices[0];
				const lastVertex = entity.vertices[entity.vertices.length - 1];

				if (firstVertex.x === lastVertex.x && firstVertex.y === lastVertex.y) {
					// If the first and last vertices are the same, the LWPOLYLINE is closed
					amount.push(entity);
				}
			}
		}

		return amount;
	}, []);
}

// Calculate Total Price
export const calculateTotalPrice = (data: IItem[]) => {
	if (!data?.length) return false;

	return data?.reduce((acc: any, cur: { price: number }) => {
		acc += +cur?.price ?? 0;

		return acc;
	}, 0);
};

// Try another approach
export const calculateTotalParams = (entities: any, type: string) => {
	return entities?.reduce(
		(
			acc: {
				cutLength: number;
				totalArea: number;
				widthX: number;
				heightY: number;
				weight: number;
			},
			entity: any,
		) => {
			if (type === "lines") {
				// Get Cut Length
				const cutLength = getDistance(
					{ x: entity.startX, y: entity.startY },
					{ x: entity.endX, y: entity.endY },
				);

				// Get total area
				const lineArea = 0;

				acc = {
					...acc,
					cutLength: acc.cutLength + cutLength,
					totalArea: (acc.totalArea += lineArea),
					weight: acc.weight + entity.lineweight,
				};
			} else if (type === "arcs") {
				const { radius, startAngle, endAngle, angleLength, lineweight } = entity;

				const cutLength = Math.abs(radius) * Math.abs(angleLength);

				// Ensure the angles are in the 0 to 2π range
				const adjustedStartAngle = ((startAngle % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);
				const adjustedEndAngle = ((endAngle % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);

				// Calculate the arc length
				let arcLength = Math.abs(adjustedStartAngle - adjustedEndAngle) * Math.abs(radius);

				// If the angle is larger than a full circle, calculate the remaining portion
				if (adjustedEndAngle < adjustedStartAngle) {
					const fullCircle = 2 * Math.PI * radius;

					arcLength = fullCircle - arcLength;
				}

				// Get total area
				const arcArea = radius * radius * angleLength;

				acc = {
					...acc,
					cutLength: acc.cutLength + cutLength,
					totalArea: (acc.totalArea += arcArea),
					weight: acc.weight + lineweight,
				};
			} else if (type === "circles") {
				const { radius, lineweight } = entity;

				// Get Cut Length
				const cutLength = getCircleDistance(radius);

				// Get total area;
				const pi = Math.PI;
				const circleArea = pi * Math.abs(radius) * Math.abs(radius);

				acc = {
					...acc,
					cutLength: acc.cutLength + cutLength,
					totalArea: (acc.totalArea += circleArea),
					weight: acc.weight + lineweight,
				};
			}

			return acc;
		},
		{ cutLength: 0, totalArea: 0, widthX: 0, heightY: 0, weight: 0 },
	);
};

export function getClosedEntitiesByType3(entities) {
	let countClosedEntities = 0;
	const closedEntitiesByType = {};
	const coordinates = {
		start: null,
		end: null,
	};
	const entitiesData = entities.slice();

	entities?.forEach((entity, index) => {
		if (entity.type === DxfEntityTypesEnum.Line) {
			if (!closedEntitiesByType[DxfEntityTypesEnum.Line])
				closedEntitiesByType[DxfEntityTypesEnum.Line] = [];

			const [point1, point2] = entity.vertices;
			const { x: x1, y: y1, z: z1 } = point1;
			const { x: x2, y: y2, z: z2 } = point2;

			if (!coordinates.start || !coordinates.end) {
				coordinates.start = point1;
				coordinates.end = point2;
			}

			const closedEntity =
				entitiesData?.length &&
				entitiesData
					.slice(index === 0 ? 0 : index)
					?.find(
						(i) =>
							x1 === i?.vertices?.[1]?.x &&
							y1 === i?.vertices?.[1]?.y &&
							z1 === i?.vertices?.[1]?.z,
					);

			const isExist =
				!!closedEntitiesByType[DxfEntityTypesEnum.Line]?.length &&
				closedEntitiesByType[DxfEntityTypesEnum.Line]?.some(
					(i) => i?.handle === closedEntity?.handle,
				);

			if (closedEntity && !isExist) {
				closedEntitiesByType[DxfEntityTypesEnum.Line].push(entity);
			}
			const startPointOfCurEntity =
				entitiesData?.length &&
				entitiesData
					.slice(index < 1 ? 0 : index + 1)
					?.find(
						(i) =>
							coordinates.end?.x === i.vertices[0].x &&
							coordinates.end?.y === i.vertices[0].y &&
							coordinates.end?.z === i.vertices[0].z,
					);

			if (
				!!startPointOfCurEntity &&
				coordinates.start?.x === startPointOfCurEntity.vertices[1]?.x &&
				coordinates.start?.y === startPointOfCurEntity.vertices[1]?.y &&
				coordinates.start?.z === startPointOfCurEntity.vertices[1]?.z
			) {
				countClosedEntities += 1;
				coordinates.start = null;
				coordinates.end = null;
			} else {
				coordinates.start = point1;
				coordinates.end = point2;
			}
		} else if (entity.type === DxfEntityTypesEnum.Circle) {
			closedEntitiesByType[DxfEntityTypesEnum.Circle] =
				closedEntitiesByType[DxfEntityTypesEnum.Circle] || [];
			closedEntitiesByType[DxfEntityTypesEnum.Circle].push(entity);
		} else if (entity.type === DxfEntityTypesEnum.Ellipse) {
			// Check if the ratio of majorAxis to minorAxis is close to 1
			const ratio = entity.majorAxis / entity.minorAxis;
			// Adjust this threshold as needed
			const threshold = 0.95;

			const isClosed = Math.abs(ratio - 1) < threshold;

			if (isClosed) {
				closedEntitiesByType[DxfEntityTypesEnum.Ellipse] =
					closedEntitiesByType[DxfEntityTypesEnum.Ellipse] || [];
				closedEntitiesByType[DxfEntityTypesEnum.Ellipse].push(entity);
			}
		} else if (entity.type === DxfEntityTypesEnum.Arc) {
			const connectedArc = closedEntitiesByType[DxfEntityTypesEnum.Arc]?.find(
				(previousArc) => Math.abs(previousArc.endAngle - entity.startAngle) < 1e-6,
			);

			if (connectedArc) {
				connectedArc.endAngle = entity.endAngle;
			} else {
				closedEntitiesByType[DxfEntityTypesEnum.Arc] =
					closedEntitiesByType[DxfEntityTypesEnum.Arc] || [];
				closedEntitiesByType[DxfEntityTypesEnum.Arc].push(entity);

				const { center, radius, startAngle, endAngle } = entity;

				const point1 = {
					x: center?.x + radius * Math.cos(startAngle),
					y: center?.y + radius * Math.sin(startAngle),
					z: 0,
				};
				const point2 = {
					x: center?.x + radius * Math.cos(endAngle),
					y: center?.y + radius * Math.sin(endAngle),
					z: 0,
				};

				if (!coordinates?.start || !coordinates?.end) {
					coordinates.start = point1;
					coordinates.end = point2;
				}

				if (coordinates.start?.x === point2.x && coordinates.start?.y === point2.y) {
					countClosedEntities += 1;
					coordinates.start = null;
					coordinates.end = null;
				} else {
					coordinates.start = point1;
					coordinates.end = point2;
				}
			}
		} else if (entity.type === DxfEntityTypesEnum.Spline) {
			if (
				arePointsEqual(
					entity.controlPoints[0],
					entity.controlPoints[entity.controlPoints.length - 1],
				)
			) {
				closedEntitiesByType[DxfEntityTypesEnum.Spline] =
					closedEntitiesByType[DxfEntityTypesEnum.Spline] || [];
				closedEntitiesByType[DxfEntityTypesEnum.Spline].push(entity);
			}
		} else if (entity.type === DxfEntityTypesEnum.LwPolyline) {
			const firstVertex = entity.vertices[0];
			const lastVertex = entity.vertices[entity.vertices.length - 1];

			if (firstVertex.x === lastVertex.x && firstVertex.y === lastVertex.y) {
				// If the first and last vertices are the same, the LWPOLYLINE is closed
				closedEntitiesByType[DxfEntityTypesEnum.LwPolyline] =
					closedEntitiesByType[DxfEntityTypesEnum.LwPolyline] || [];
				closedEntitiesByType[DxfEntityTypesEnum.LwPolyline].push(entity);
			}
		}
	});

	const amount = Object.values(closedEntitiesByType).reduce(
		(acc, i) => (i.length ? (acc += i.length) : acc),
		0,
	);

	return { closedEntitiesByType, amount };
}
