import axios from "axios";
import { isEmpty } from "lodash";

import { GenericType } from "../types";

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
	baseURL,
});

export const get = async (url: string) => {
	if (!url) return null;

	try {
		const response = await api.get(url);

		return response?.data;
	} catch (e) {
		console.error(e);
	}
};

export const post = async (url: string, body: GenericType | FormData) => {
	if (!url && isEmpty(body)) return null;

	try {
		const response = await api.post(url, body);

		return response?.data;
	} catch (e) {
		console.error(e);
	}
};

export const put = async (url: string, body: GenericType) => {
	if (!url && isEmpty(body)) return null;

	try {
		const response = await api.put(url, body);

		return response?.data;
	} catch (e) {
		console.error(e);
	}
};

export const patch = async (url: string, body: GenericType) => {
	if (!url && isEmpty(body)) return null;

	try {
		const response = await api.patch(url, body);

		return response?.data;
	} catch (e) {
		console.error(e);
	}
};

export default api;
