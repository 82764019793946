export default {
	black: "#1E1E20",
	darkGray5: "#54545D",
	darkGray4: "#2A2A2C",
	darkGray3: "#39393B",
	darkGray2: "#5C5C60",
	darkGray1: "#9D9DA1",
	gray: "#C4C4C8",
	lightGray1: "#DEDDE1",
	lightGray2: "#EBEBED",
	lightGray3: "#F8F8F8",
	white: "#FFFFFF",
	bluePulse: "rgba(49,78,255,0.2)",
	blueOverlay: "rgba(49, 78, 255, 0.1)",
	blue: "#314EFF",
	blue1: "#001DCC",
	darkBlue: "#262834",
	lightBlue: "#99A7FF33",
	lightBlue1: "#99A7FF",
	lightBlue2: "#7A8DFF",
	lightBlue3: "#f8f9fc",
	lightBlue4: "#d4daed",
	darkBlue1: "#001699",
	darkBlue2: "#212B3C",
	green: "#52C41A",
	red: "#FF4D4F",
	orange: "#FAAD14",
	yellow: "#F2C94C",
	mintGreen: "#3EEBD0",
	neutral2: "#FAFAFA",
	neutral5: "#D9D9D9",
	iconColor: "#9BA4C2",
	badgeColor: "#F03F3F",
	inputColor: "#4096ff",
	settingsIconColor: "rgba(153, 167, 255, 0.1)",
	settingsItemColor: "rgba(153, 167, 255, 0.2)",
	labelColor: "rgba(153, 167, 255, 0.4)",
	elevationShadow: "0 4px 12px rgba(99, 111, 122, 0.16)",
	elevationShadow1: "0px 2px 4px rgba(99, 111, 122, 0.12)",
	elevationAntInputShadow: "0 0 0 2px rgba(5, 145, 255, 0.1)",
	borderColor: "var(--light-grey-2, #EBEBED)",
} as const;
