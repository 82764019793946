import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "antd";

import { Storage } from "types";

const options = [
	{ value: "en", label: "Eng" },
	{ value: "ua", label: "Укр" },
];

const LanguageSwitcher: React.FC = () => {
	const { i18n } = useTranslation();

	const storedLanguage = localStorage.getItem(Storage.Lang);

	const [value, setValue] = useState(options[0].value);

	const handleLanguageChange = (value: string) => {
		if (value) {
			setValue(value);
			i18n.changeLanguage(value);
			localStorage.setItem(Storage.Lang, value);
		}
	};

	useEffect(() => {
		storedLanguage && handleLanguageChange(storedLanguage);
	}, [storedLanguage, i18n]);

	return (
		<Select value={value} onChange={handleLanguageChange}>
			{options.map((option) => (
				<Select.Option key={option.value} value={option.value}>
					{option.label}
				</Select.Option>
			))}
		</Select>
	);
};

export default LanguageSwitcher;
